.auth_layout{
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: rgba(0,0,0,0.88);
    /*background: url('https://orange.codeberryschool.com/content/images/project-assets/simple-footer.png') repeat center;*/
    background-image: url('./landingpage-art-effect.svg');
    background-repeat:no-repeat;
    /*#24E5AF 0%, #ffffff 100%);*/
    /*background-image: url("https://bmisw.com/wp-content/uploads/2011/01/blur-background-26350-27041-hd-wallpapers.png");*/
    padding: 2rem;
}

.auth_content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth_card{
    background-color: #f4f4f4;
    height: auto;
    min-width: 20vw;
    max-width: 30rem;
    margin-top: 4vh;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 3px 3px 8px #3d3d3d;
}

.auth_navbar__logo{
    margin-left: 1.5rem;
    color: rgba(0,0,0,1);
}

.auth_body {
    display: flex;
    width: 100%;
}

.auth_content {
    flex: 1;
}

.auth_description {
    flex: 1;
    padding: 1rem;
    align-self: flex-end;
    /*background-color: red;*/
    display: flex;
    justify-content: flex-end;
}

.auth_resume_image {
    width: 100%;
    min-width: 70%;
    max-width: 658px;
    max-height: 837px;
    height: 100%;
    object-fit: cover;
}

.auth_resume_paragraph {
    margin: 0.7rem;
}

.auth_resume_text {
    color: white;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 0.3rem;
}

@media only screen and (max-width: 1026px) {
    #fadeshow1 {
        display: none;
    }
}

.auth_card {
    background-color: #f4f4f4;
}

.auth_navbar{
    display:flex;
    width: 100%;
    background-color: #d1ffe0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    padding-right: 1rem;
    border-radius: 0.5rem;
}

.left__nav > a{
    margin-left: 0.5rem;
}