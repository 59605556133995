$hover-color: #cfcfcf;
$selected-color: #efefef;

.resumes__content-body{
  margin-left: 2rem;
  margin-top: 2rem;
  margin-right: 2rem;
  display:flex;
}

.resumes__content-body-left {
  flex: 6;
  margin-right: 2rem;
}

.resumes__content-body-right {
  flex: 4;
}


.resumes__table_head{
  background-color: #f4f4f4;
}

.resumes__table_row:hover {
  background-color: $hover-color;
  cursor: pointer;
}

.resumes_new__table_header_row, .resumes_new__table_row{
  display: flex;
}

.resumes_new__table_title{
  flex: 0.3;
}

.resumes_new__table_description{
  flex: 1;
}

.resumes_new__table{
  margin-bottom: 0.2rem;
}

.resumes__preview_container{
  display:flex;
}

.resumes__selected_project_divider{
  margin-bottom: 0.5rem;
}

#resumeFrame{
  width: 100%;
  height: 70vh;
  position: relative;
  border: none;
}

.resumes__pdf_preview{
  border: 1px solid #ececec;
  margin-bottom: 1rem;
}

.resumes__template_row{
  display:flex;
}

.resumes__table_row_selected {
  background-color: $selected-color;
}

.resumes__template_row > div {
  margin-right: 0.5rem;
}

.resumes__template_item{
  margin-bottom: 1rem;
  line-height: 1rem;
  vertical-align: bottom;
}

.resumes__template_item:hover{
  border: 2px solid #d1ffe0;
}

.resumes__template_item_label{

  margin-top: 0.5rem;
}

.resumes__template_item_radio{
  margin-left: 1rem;
}