$hover-color: #cfcfcf;
$selected-color: #efefef;

.projects__content-body{
  margin-left: 2rem;
  margin-top: 2rem;
  margin-right: 2rem;
  display:flex;
}

.projects__content-body-left {
  flex: 6;
  margin-right: 2rem;
}

.projects__content-body-right{
  flex: 4;

}

.projects__ordering_preview_container{
  display:flex;
  vertical-align: baseline;
}

.projects__table_head{
  background-color: #f4f4f4;
}

.projects__table_row:hover {
  background-color: $hover-color;
  cursor: pointer;
}

.projects__table_row_selected {
  background-color: $selected-color;
}

.project__selected_project_divider{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}