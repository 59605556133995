$hover-color: #cfcfcf;

.cover_letters__content-body{
  margin-left: 2rem;
  margin-top: 2rem;
  margin-right: 2rem;
  display:flex;
}

.cover_letters__content-body-left {
  flex: 6;
  margin-right: 2rem;
}

.cover_letters__table_head{
  background-color: #f4f4f4;
}

