$background: #f4f4f4;
$divider-color: #585959;

.banner{
  width: 100%;
  background-color: #d1ffe0;
  height: 4rem;
  //border-top: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;
}

.banner__content{
  display:flex;
  justify-content: space-between;
}

.banner__content_left{
  display:flex;
  vertical-align: 10%;
  padding-top: 0.5rem;
}

.banner__content_right{
  display:flex;
  padding-top: 0.8rem;
  padding-right: 1rem;
}

.banner__icon{
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}