.header{
    display:flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
    background-color: rgb(140, 236, 140);
}

.header__left{
    flex: 0.5;
    /* min-width: 70px; */
    display: flex;
    align-items: center;
    /* background-color: white; */
    color: grey;
    /* border-radius: 30px; */
    padding: 10px;
}

.header__left > input {
    border: none;
    width: 100%;
}

.header__left > input:focus {
    outline-width: 0;
}

.header__right{
    display:flex;
    align-items: center;
}

.header__right > h4 {
    margin-left: 10px;
    font-weight: 500;
}

/* Bootstrap dropdown */
.dropdown-toggle{
    background-color: transparent;
    display:flex;
    align-items: center;
    outline: none;
    border: none;
}

.dropdown-toggle > *{
    margin-right: 4px;
}

.avatar__name{
    margin-top: 10px;
}
