
$background: #f4f4f4;
$font-color: #000000;
$divider-color: #585959;

.sidebar {
  min-height: 100vh;
  height: auto;
  min-width: 150px;
  width: 16vw;
  max-width: 16vw;
  background-color: $background;
  background: $background;
  color: $font-color;
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  z-index: 500;
  top: 4rem;
  left: 0;
}


.sidebar__logo {
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
  padding: 10px;
  color: $font-color;
  margin-bottom: 1rem !important;
  font-size: 2rem !important;
}

.sidebar__title {
  margin-left: 10px;
  padding: 5px;
  font-size: 12px;

}

.sidebar > hr {
  border: 1px solid #282828;
  width: 90%;
  margin: 10px auto;
}

.sidebar__option_container{
  display: flex;
  flex-direction: column;
}

.sidebar__option{
  height: 3rem;
  border-color: $divider-color;
}

.sidebar__divider{
  height: 1px;
  margin-left: -1rem;
  margin-right: -1rem;
  background-color: $divider-color;
}
