$selected-color: #efefef;

.skills__content-body {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-right: 2rem;
    display:flex;
}

.skills__content-body-left {
    flex: 6;
    margin-right: 1rem;
}

/*.skills__content-body-left2 {*/
/*    !*flex: 6;*!*/
/*    width: 60%;*/
/*    margin-right: 1rem;*/
/*}*/

.skills__content-body-right {
    flex: 4;
}

.skills__ordering_preview_container{
    display:flex;
    vertical-align: baseline;
}
.skills__table_head {
    background-color: #f4f4f4;
}

.skills__table_row:hover {
    background-color: #cfcfcf;
    cursor: pointer;
}

.skills__selected_skill_divider{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.skills__table_row_selected {
    background-color: $selected-color;
}

/*.btn_skill {*/
/*    margin-top: 20px;*/
/*}*/
