
.uishell{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.uishell__main-block{
  display: flex;
}

.uishell__content-body{
  flex: 1;
  margin-left: 16vw;
}
