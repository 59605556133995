$hover-color: #cfcfcf;
$selected-color: #efefef;

.qualifications__content-body {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-right: 2rem;
    display:flex;
}

.qualifications__content-body-left {
    flex: 6;
    margin-right: 2rem;
}

.qualifications__content-body-right {
    flex: 4;
}

.qualifications__ordering_preview_container{
    display:flex;
    vertical-align: baseline;
}
.qualifications__table_head {
    background-color: #f4f4f4;
}

.qualifications__table_row:hover {
    background-color: #cfcfcf;
    cursor: pointer;
}

.qualifications__table_row_selected {
    background-color: $selected-color;
}

.skill__selected_skill_divider{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}