$hover-color: #cfcfcf;
$divider-color: #585959;
$font-color: #ffffff;


.sidebarOption{
  padding: 0.5rem 0rem 0.5rem 1rem;
  height: 100%;
  margin-left: -1rem;
  margin-right: -1rem;

  //padding-left: 1rem;
  //border-bottom: 1px solid $divider-color;
  //border-top: 1px solid $divider-color;
  display: flex;
}

.sidebarOption__container{
  margin-left: 1rem;
  margin-top: 0.2rem;
  font-size: 0.9em;
}

.sidebarOption:hover{
  background-color: $hover-color;
  margin-bottom: 1rem;
  color: black;
}

.sidebarOption > a:hover {
  text-decoration: none;
}

.sidebarOption__icon{
  margin-right: 1rem;
}