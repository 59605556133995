
.login{
    display: flex;
    flex-direction: row;
}

.login__heading{
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
}

.login__register_link{
    align-self: flex-end;
    margin-top: 0.4rem;
    font-size: 0.8rem;
}

.login__disclaimer{
    align-self: flex-start;
    margin-top: 0.4rem;
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
}

.red{
    color: red;
}

.login__left_container{
    border-right: 1px solid black;
    margin-right: 1rem;
    padding-right: 1rem;
}

.login__right_container{
    max-width: 10rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5rem;
}

.login__demo_account_button{
    padding: 1rem;
    font-size: 0.65rem !important;
    height: 2rem;
    /*height: 20px;*/
}