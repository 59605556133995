$background-color-tableheader: #f4f4f4;

* {
  margin: 0;
}


.uishell__child-content-body{
  margin-left: 2rem;
  margin-top: 1rem;
}

/* ==== GENERAL STYLES ==== */

* {
  box-sizing: border-box;
}

strong {
  font-weight: 700;
}

/* ==== SIMPLE FOOTER ==== */

footer {
  background: url('https://orange.codeberryschool.com/content/images/project-assets/simple-footer.png') repeat center;
  display: table;
  width: 100%;
  height: 2rem;
  text-align: center;
  padding: 1rem;
  font-family: 'Arial', sans-serif;
  font-size: .875rem;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
}

footer small {
  color: #ccc;
  letter-spacing: .025rem;
  margin-bottom: 1rem;
  display: block;
}

.footer-nav a {
  color: #c74f78;
  text-decoration: none;
  margin: 0.5rem;
  display: inline-block;
}

.footer-nav a:hover {
  color: #f26896;
  transition: color .15s ease-in-out;
}


