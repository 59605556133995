.dashboard{
    display: flex;
}


.dashboard__body {
    flex: 0.85;
    display: flex;
    flex-direction: column;
}

.dashboard__content {
    background: rgba(220, 220, 220, 0.8);
    height: 100vh;
    color: black;
    padding: 30px;
    overflow-y: overlay;
    margin-bottom: 50px;
}