
.register{
    display: flex;
    flex-direction: column;
}

.register__heading{
    margin-bottom: 2rem;
}

.register__login_link{
    align-self: flex-end;
    padding-top: 0.3rem;
    font-size: 0.8rem;
}