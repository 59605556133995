$background: #292929;
$font-color: #cfd1d0;

.navbar{
  width: 100%;
  min-height: 4rem;
  background-color: $background;
  color: $font-color;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar__menu{
  height: 100%;
  line-height: 1.5rem;
}

.navbar__logo{
  margin-left: 1.5rem;
}

.navbar__right{
  height: 100%;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.navbar__avatar{
  margin-right: 0.5rem;
}

.navbar__menu_container{
  vertical-align: 10%;
}

.navbar__menu:focus{
}

.navbar__username{
}

.navbar__dropdown{
  color: black !important;
}